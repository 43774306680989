import React from 'react';
import styled from 'styled-components'

function App () {
  return (
    <AppContainer>
      <PageText>Medieval Snail Limited</PageText>
      <PageSubtest>Coming Soon...</PageSubtest>
      <PageSubtest>Email: daniel@danielfern.co.uk</PageSubtest>
    </AppContainer>
  )
};

export default App;

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #421F51;
  margin: 0;
  padding: 0;
`

const PageText = styled.h1`
  color: #F2E3F1;
  font-family: Sans-Serif;
`

const PageSubtest = styled.h2`
color: #F2E3F1;
font-family: Sans-Serif;
`